<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card outlined class="transparent">
          <v-card-title class="transparent">
            {{ routingType.name }} ({{ routingType.shortName }})
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <RoutingTemplates
      :onlyActive="false"
      v-on:chosenRouting="chooseThisRoutingTemplate"
    >
      <v-btn @click="goToNewTemplate()"> Maak een nieuwe routing. </v-btn>
    </RoutingTemplates>
  </v-container>
</template>

<script>
import RoutingTemplates from "@/components/RoutingTemplates";
import { mapState } from "vuex";
export default {
  components: { RoutingTemplates },
  data() {
    return {};
  },
  computed: {
    routingType() {
      return this.board.columns[1].routingType;
    },
    ...mapState({
      board: (state) => state.board,
    }),
  },
  methods: {
    chooseThisRoutingTemplate(routing) {
      if (routing) {
        // console.log(routing);
        this.$store.commit("COPY_ROUTING", {
          routing,
        });
      }
      this.$router.push({ name: "templates/board" });
    },
    goToNewTemplate() {
      let routingType = JSON.parse(
        JSON.stringify(this.board.columns[1].routingType)
      );
      const job = null;
      this.$store.commit("SET_JOB", {
        job,
      });
      this.board.columns[1].routingType = routingType;
      this.board.columns[1].routingTypeId = routingType.id;

      this.$router.push({ name: "templates/board" });
    },
  },
};
</script>
