<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card tile :loading="loading" outlined class="transparent">
          <v-card-title class="transparent">
            Kies een routing
            <v-spacer />
            <slot />
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-show="!loading">
      <v-col>
        <v-row>
          <v-col
            v-for="routing in routingTemplates"
            :key="routing.id"
            cols="12"
            sm="6"
            lg="4"
            xl="3"
          >
            <v-card
              class="grey lighten-5 clickable"
              @click="chooseThisRoutingTemplate(routing)"
            >
              <v-card-title class="grey lighten-2 mb-3">
                {{ routing.name }}
                <v-spacer />
                <span v-if="!onlyActive">
                  <v-icon v-if="routing.isActive"> mdi-eye </v-icon>
                  <v-icon v-else>mdi-eye-off</v-icon>
                </span>
              </v-card-title>
              <v-card-text>
                <v-timeline align-top dense>
                  <v-timeline-item
                    v-for="routingStep in routing.routingStepTemplates"
                    :key="routingStep.Id"
                    :color="routingStep.color"
                    small
                  >
                    <div>
                      <div class="font-weight-normal">
                        <strong>{{ routingStep.operationName }}</strong>
                        ({{ routingStep.tactTime }} min)
                      </div>
                      <div>{{ routingStep.cellName }}</div>
                    </div>
                  </v-timeline-item>
                </v-timeline>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <!-- <v-row>
          <v-col>
            <v-card @click="chooseThisRoutingTemplate(null)">
              <v-card-title class="grey lighten-2">New routing</v-card-title>
            </v-card>
          </v-col>
        </v-row> -->
      </v-col>
    </v-row>
  </div>
</template>

<script>
import services from "../services/services.js";
import { mapState } from "vuex";
export default {
  props: {
    onlyActive: {
      type: Boolean,
      required: true,
    },
  },
  created() {
    let routingTypeId = this.board.columns[1].routingTypeId;

    services
      .getRoutingTemplates(routingTypeId, this.onlyActive)
      .then((response) => {
        // console.log(response);
        this.routingTemplates = response.data;
        this.routingTemplates.forEach((routing) => {
          routing.routingStepTemplates.sort(function (a, b) {
            return a.sortKey - b.sortKey;
          });
        });
      })
      .catch((error) => {
        this.$store.commit("SAVE_ERROR", { error });
        console.log("There are errors: ", error.response);
      })
      .finally(() => {
        this.loading = false;
      });
  },
  data() {
    return {
      routingTemplates: [],
      loading: true,
    };
  },
  computed: {
    ...mapState(["board"]),
  },
  methods: {
    chooseThisRoutingTemplate(routing) {
      this.$emit("chosenRouting", routing);
    },
  },
};
</script>
